import React, { useState, useRef, useEffect } from 'react'
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/24/solid'

const MultiSelect = ({ options, onChange, defaultValue }) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultValue || [])
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const toggleOption = (option) => {
    console.log('log option', option)
    const newSelection = selectedOptions
      .map((item) => item.id)
      .includes(option.id)
      ? selectedOptions.filter((item) => item.id !== option.id)
      : [...selectedOptions, option]

    setSelectedOptions(newSelection)
    onChange && onChange(newSelection)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const displayValue =
    selectedOptions.map((option) => option.name).length > 0
      ? selectedOptions.map((option) => option.name).join(', ')
      : 'Select options'

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="relative w-full cursor-default rounded-md bg-white border border-blue-gray-200 py-3 pl-3 pr-10 text-left focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm"
      >
        <span className="block truncate text-black">{displayValue}</span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </div>
      {isOpen && (
        <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {options.map((option) => (
            <li
              key={option.id}
              className={`relative cursor-default select-none py-2 pl-10 pr-4 ${
                selectedOptions.filter((item) => item.id === option.id).length >
                0
                  ? 'bg-blue-100 text-blue-900'
                  : 'text-gray-900'
              }`}
              onClick={() => toggleOption(option)}
            >
              <span
                className={`block truncate ${
                  selectedOptions.filter((item) => item.id === option.id)
                    .length > 0
                    ? 'font-bold'
                    : 'font-normal'
                }`}
              >
                {option.name}
              </span>
              {selectedOptions.filter((item) => item.id === option.id).length >
                0 && (
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default MultiSelect
