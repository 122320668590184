import { PencilIcon } from '@heroicons/react/24/solid'
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  Input,
  Textarea,
  DialogFooter,
  Option,
  Select,
  DialogBody,
  DialogHeader,
  Dialog,
} from '@material-tailwind/react'
import Sidebar from '../../../components/Sidebar'
import { useEffect, useState } from 'react'
import { z } from 'zod'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  useDeleteProduct,
  useFetchAllProducts,
  usePostProducts,
} from '../../../hooks/usePlacementTest'
import { getLevelDescription } from '../../../helpers/placementTest'
import MultiSelect from '../../../components/MultiSelect'

const TABLE_HEAD = [
  'Product Name',
  'Level',
  'Other Level',
  'Description',
  'Promo Price',
  'Normal Price',
  'Benefits',
  'Link',
  '',
]

const benefitSchema = z.object({
  name: z.string().min(1, 'Benefit is required'),
})

const listOtherLevelSchema = z.object({
  id: z.string(),
  name: z.string(),
})

const schema = z.object({
  product_name: z.string().min(1, 'Product name is required'),
  level: z.string().min(1, 'Level is required'),
  description_product: z.string().min(0, 'Description is required'),
  price_promo: z.string().min(1, 'Promo price is required'),
  price_normal: z.string().min(1, 'Normal price is required'),
  link: z.string().min(0, 'Link is required'),
  list_benefit: z.array(benefitSchema).min(1, {
    message: `You need to add at least 1 benefit`,
  }),
  list_level_product_lainnya: z.array(listOtherLevelSchema).min(1, {
    message: `You need to add at least 1 level`,
  }),
})

const PlacementTestAddProducts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const { mutate: mutatePostProducts, isSuccess: isSuccessPostProducts } =
    usePostProducts()
  const { mutate: deleteProduct } = useDeleteProduct({
    onSuccess: () => {
      setSelectedProduct(null)
      setIsModalDeleteOpen(false)
    },
  })
  const {
    data: dataProducts,
    isPending: isLoadingProducts,
    isError: isErrorProducts,
    error: errorProducts,
  } = useFetchAllProducts({ category: 'ielts' })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    defaultValues: {
      product_name: '',
      level: '',
      description_product: '',
      price_promo: '',
      price_normal: '',
    },
    resolver: zodResolver(schema),
  })

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'list_benefit',
  })

  const handleOpenModal = () => setIsModalOpen(!isModalOpen)
  const handleOpenModalDelete = () => setIsModalDeleteOpen(!isModalDeleteOpen)

  const onSubmit = (data) => {
    mutatePostProducts(
      selectedProduct ? { ...data, id: selectedProduct.id } : data
    )
  }

  useEffect(() => {
    if (isSuccessPostProducts) {
      setIsModalOpen(false)
    }
  }, [isSuccessPostProducts])

  const TABLE_ROWS = dataProducts?.data
    ?.map((product) => {
      return {
        ...product,
        list_benefit: JSON.parse(product.list_benefit),
      }
    })
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

  const handleDeleteProduct = (product) => {
    handleOpenModalDelete()
    setSelectedProduct(product)
  }

  const handleEditProdcut = (product) => {
    handleOpenModal()
    setSelectedProduct(product)
    setValue('product_name', product.product_name)
    setValue('level', product.level)
    setValue('description_product', product.description_product)
    setValue('price_promo', product.price_promo)
    setValue('price_normal', product.price_normal)
    setValue('link', product.link)
    setValue(
      'list_level_product_lainnya',
      product?.list_level_product_lainnya
        ? JSON.parse(product?.list_level_product_lainnya).map((level) => ({
            id: level,
            name: getLevelDescription(level),
          }))
        : []
    )

    product.list_benefit.forEach((benefit, index) => {
      update(index, { name: benefit })
    })
    clearErrors()
  }

  const handleAddProduct = () => {
    handleOpenModal()
    setSelectedProduct(null)

    setValue('product_name', '')
    setValue('level', '')
    setValue('description_product', '')
    setValue('price_promo', '')
    setValue('price_normal', '')
    setValue('link', '')
    setValue('list_benefit', [])
    setValue('list_level_product_lainnya', [])
  }

  const levelOptions = [
    { id: 'beginner', name: 'Beginner/Pemula (1-10)' },
    { id: 'elementary', name: 'Elementary/Dasar (11-15)' },
    { id: 'intermediate', name: 'Intermediate/Menengah (16-18)' },
    {
      id: 'high_intermediate',
      name: 'High Intermediate/Menengah (19-20)',
    },
    {
      id: 'upper_intermediate',
      name: 'Upper Intermediate/Menengah Atas (21-25)',
    },
  ]

  return (
    <div className="flex lg:grid lg:grid-cols-10">
      <div>
        <Sidebar />
      </div>
      <div className="w-full flex lg:col-start-3 lg:col-end-12 mt-2 lg:mt-10">
        <Card className="h-full w-full">
          <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
              <div>
                <Typography variant="h5" color="blue-gray">
                  Placement Test (Add Products)
                </Typography>
              </div>
              <div className="flex w-full shrink-0 gap-2 md:w-max">
                <Button
                  className="flex items-center gap-3"
                  size="sm"
                  onClick={handleAddProduct}
                >
                  <PlusIcon strokeWidth={2} className="h-4 w-4" /> Add Product
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardBody className="overflow-auto px-0">
            {isLoadingProducts && <p className="text-center">Loading...</p>}
            {isErrorProducts && (
              <p className="text-center">
                Something went wrong. Error: {errorProducts?.message || '404'}
              </p>
            )}
            {dataProducts?.data && (
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {TABLE_ROWS.map((product, index) => {
                    const isLast = index === TABLE_ROWS.length - 1
                    const classes = isLast
                      ? 'p-4'
                      : 'p-4 border-b border-blue-gray-50'

                    return (
                      <tr key={product.id}>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold"
                            >
                              {product.product_name}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {getLevelDescription(product.level)}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            <ul className="list-disc">
                              {(product.list_level_product_lainnya
                                ? JSON.parse(
                                    product.list_level_product_lainnya
                                  ).map((level) => getLevelDescription(level))
                                : []
                              ).map((level) => (
                                <li>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {level}
                                  </Typography>
                                </li>
                              ))}
                            </ul>

                            {!product.list_level_product_lainnya && (
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                -
                              </Typography>
                            )}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {product.description_product.trim()
                              ? product.description_product
                              : '-'}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {product.price_promo}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {product.price_normal}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <ul className="list-disc">
                            {product.list_benefit.map((benefit) => (
                              <li>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {benefit}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue"
                            className="font-normal"
                            as="a"
                            target="_blank"
                            rel="noreferrer"
                            href={product.link ? product.link : '#'}
                          >
                            {product.link ? 'Link' : '-'}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Tooltip content="Edit Product">
                            <IconButton
                              variant="text"
                              onClick={() => handleEditProdcut(product)}
                            >
                              <PencilIcon className="h-4 w-4" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip content="Delete Product">
                            <IconButton
                              variant="text"
                              color="red"
                              onClick={() => handleDeleteProduct(product)}
                            >
                              <TrashIcon className="h-4 w-4" />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </CardBody>
        </Card>
        <Dialog
          size="sm"
          open={isModalOpen}
          handler={handleOpenModal}
          className="p-4"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogHeader className="relative m-0 block">
              <Typography variant="h4" color="blue-gray">
                {selectedProduct ? 'Edit' : 'Add'} Product
              </Typography>
              <IconButton
                size="sm"
                variant="text"
                className="!absolute right-3.5 top-3.5"
                onClick={handleOpenModal}
              >
                <XMarkIcon className="h-4 w-4 stroke-2" />
              </IconButton>
            </DialogHeader>
            <DialogBody className="space-y-4 pb-6 max-h-[calc(100vh-200px)] overflow-y-auto">
              <div>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-2 text-left font-medium"
                >
                  Name
                </Typography>
                <Input
                  color="gray"
                  size="lg"
                  className="placeholder:opacity-100 focus:!border-t-gray-900"
                  containerProps={{
                    className: '!min-w-full',
                  }}
                  labelProps={{
                    className: 'hidden',
                  }}
                  {...register('product_name')}
                />
                {errors.product_name && (
                  <Typography className="text-red-500 text-xs font-normal mt-2">
                    {errors.product_name.message}
                  </Typography>
                )}
              </div>
              <div>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-2 text-left font-medium"
                >
                  Level
                </Typography>
                <Controller
                  control={control}
                  name="level"
                  render={({ field: { onChange } }) => (
                    <Select
                      className="!w-full !border-[1.5px] !border-blue-gray-200/90 !border-t-blue-gray-200/90 bg-white text-gray-800 ring-4 ring-transparent placeholder:text-gray-600 focus:!border-primary focus:!border-t-blue-gray-900 group-hover:!border-primary"
                      labelProps={{
                        className: 'hidden',
                      }}
                      onChange={onChange}
                      value={getValues('level')}
                    >
                      {levelOptions.map((option) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                />

                {errors.level && (
                  <Typography className="text-red-500 text-xs font-normal mt-2">
                    {errors.level.message}
                  </Typography>
                )}
              </div>
              <div>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-2 text-left font-medium"
                >
                  Show on Other Level
                </Typography>
                <Controller
                  control={control}
                  name="list_level_product_lainnya"
                  render={({ field: { onChange } }) => (
                    <MultiSelect
                      onChange={onChange}
                      options={levelOptions}
                      defaultValue={getValues('list_level_product_lainnya')}
                    />
                  )}
                />

                {errors.list_level_product_lainnya && (
                  <Typography className="text-red-500 text-xs font-normal mt-2">
                    {errors.list_level_product_lainnya.message}
                  </Typography>
                )}
              </div>

              <div className="flex gap-4">
                <div className="w-full">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-2 text-left font-medium"
                  >
                    Normal Price
                  </Typography>
                  <Input
                    color="gray"
                    size="lg"
                    name="weight"
                    className="placeholder:opacity-100 focus:!border-t-gray-900"
                    containerProps={{
                      className: '!min-w-full',
                    }}
                    labelProps={{
                      className: 'hidden',
                    }}
                    {...register('price_normal')}
                  />
                  {errors.price_normal && (
                    <Typography className="text-red-500 text-xs font-normal mt-2">
                      {errors.price_normal.message}
                    </Typography>
                  )}
                </div>
                <div className="w-full">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-2 text-left font-medium"
                  >
                    Promo Price
                  </Typography>
                  <Input
                    color="gray"
                    size="lg"
                    name="size"
                    className="placeholder:opacity-100 focus:!border-t-gray-900"
                    containerProps={{
                      className: '!min-w-full',
                    }}
                    labelProps={{
                      className: 'hidden',
                    }}
                    {...register('price_promo')}
                  />
                  {errors.price_promo && (
                    <Typography className="text-red-500 text-xs font-normal mt-2">
                      {errors.price_promo.message}
                    </Typography>
                  )}
                </div>
              </div>
              <div>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-2 text-left font-medium"
                >
                  Link (Optional)
                </Typography>
                <Input
                  color="gray"
                  size="lg"
                  className="placeholder:opacity-100 focus:!border-t-gray-900"
                  containerProps={{
                    className: '!min-w-full',
                  }}
                  labelProps={{
                    className: 'hidden',
                  }}
                  {...register('link')}
                />
                {errors.link && (
                  <Typography className="text-red-500 text-xs font-normal mt-2">
                    {errors.link.message}
                  </Typography>
                )}
              </div>
              <div>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-2 text-left font-medium"
                >
                  Description (Optional)
                </Typography>
                <Textarea
                  rows={7}
                  className="!w-full !border-[1.5px] !border-blue-gray-200/90 !border-t-blue-gray-200/90 bg-white text-gray-600 ring-4 ring-transparent focus:!border-primary focus:!border-t-blue-gray-900 group-hover:!border-primary"
                  labelProps={{
                    className: 'hidden',
                  }}
                  {...register('description_product')}
                />
                {errors.description_product && (
                  <Typography className="text-red-500 text-xs font-normal mt-2">
                    {errors.description_product.message}
                  </Typography>
                )}
              </div>
              <div>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-2 text-left font-mdfasdium"
                >
                  List of Benefits
                </Typography>
                <div className="flex flex-col gap-3">
                  {fields.map((field, index) => (
                    <div>
                      <div key={index} className="flex items-center gap-3">
                        <Input
                          color="gray"
                          size="lg"
                          name="size"
                          className="placeholder:opacity-100 focus:!border-t-gray-900"
                          labelProps={{
                            className: 'hidden',
                          }}
                          key={field.id}
                          {...register(`list_benefit.${index}.name`)}
                        />
                        <IconButton
                          type="button"
                          onClick={() => remove(index)}
                          size="sm"
                          color="red"
                        >
                          <TrashIcon className="h-4 w-4 stroke-2" />
                        </IconButton>
                      </div>
                      {errors.list_benefit && (
                        <Typography className="text-red-500 text-xs font-normal mt-2">
                          {errors.list_benefit[index]?.name?.message}
                        </Typography>
                      )}
                    </div>
                  ))}
                  {errors.list_benefit && (
                    <Typography className="text-red-500 text-xs font-normal mt-2">
                      {errors.list_benefit.message}
                    </Typography>
                  )}
                </div>

                <Button
                  type="button"
                  onClick={append}
                  className="flex items-center gap-3 mt-4"
                  size="sm"
                >
                  <PlusIcon strokeWidth={2} className="h-4 w-4" /> Add Benefit
                </Button>
              </div>
            </DialogBody>
            <DialogFooter>
              <Button className="ml-auto" type="submit">
                {selectedProduct ? 'Update' : 'Add'} Product
              </Button>
            </DialogFooter>
          </form>
        </Dialog>
        <Dialog
          open={isModalDeleteOpen}
          handler={handleOpenModalDelete}
          size="xs"
        >
          <DialogHeader className="text-center">
            Are you sure want to delete {selectedProduct?.product_name} product?
          </DialogHeader>
          <DialogFooter className="flex justify-center gap-4">
            <Button color="gray" variant="text" onClick={handleOpenModalDelete}>
              <span>Cancel</span>
            </Button>
            <Button
              color="red"
              onClick={() => deleteProduct({ id: selectedProduct.id })}
            >
              <span>Delete</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  )
}

export default PlacementTestAddProducts
