import axios from '../utils/axios'

export const postProducts = async ({
  id,
  product_name,
  level,
  description_product,
  price_promo,
  price_normal,
  link,
  list_benefit,
  list_level_product_lainnya,
}) => {
  try {
    const res = await axios.post('/placement-test/products/ielts', {
      items: [
        {
          id,
          product_name,
          level,
          description_product,
          price_promo,
          price_normal,
          link,
          list_benefit: list_benefit.map((benefit) => benefit.name),
          list_level_product_lainnya: list_level_product_lainnya.map(
            (benefit) => benefit.id
          ),
        },
      ],
    })

    return {
      res,
    }
  } catch (error) {
    throw new Error(
      `Failed to create products: ${
        error.response?.data?.message || error.message
      }`
    )
  }
}

export const deleteProduct = async ({ id }) => {
  try {
    const res = await axios.delete('/placement-test/products/ielts', {
      data: {
        id,
      },
    })

    return {
      res,
    }
  } catch (error) {
    throw new Error(
      `Failed to delete products: ${
        error.response?.data?.message || error.message
      }`
    )
  }
}

export const fetchProducts = async ({ category, level }) => {
  const res = await axios.get(`/placement-test/products/${category}/${level}`)
  const { data } = res.data

  return {
    data,
  }
}

export const fetchAllProducts = async ({ category }) => {
  const res = await axios.get(`/placement-test/products/${category}`)
  const { data } = res.data

  return {
    data,
  }
}
